import React from "react"
import "../index.scss"

import Layout from "src/components/layout"
import Head from "../../components/Head"

import ClientSticker from "src/components/App/ClientSticker/ClientSticker"
import GymSticker from "src/components/App/GymSticker/GymSticker"
import Button from "../../components/common/Button/Button"
import { graphql, useStaticQuery } from "gatsby"

const AppMain = ({ location }) => {

  const data = useStaticQuery(graphql`
    query appPageClientsBy {
      site {
        siteMetadata {
          countries {
            by {
              hotLine {
                tel
                title
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout
      hotLine={data.site.siteMetadata.countries.by.hotLine}
      location={location}
      country="by"
    >
      <Head
        lang="by"
        title="Активировать Allsports"
        slug="/activate/"
        apps={true}
      />
      <section className="main-page">
        <div className="buttons-wrapper">
          <a
            className="button blue"
            href="https://play.google.com/store/apps/details?id=by.allsports.holder"
          >
            Android Приложение
          </a>
        </div>
        <div className="buttons-wrapper">
          <a
            className="button blue"
            href="https://appgallery.huawei.com/app/C104288727"
          >
            Huawei Приложение
          </a>
        </div>
        <div className="buttons-wrapper">
          <a
            className="button blue"
            href="https://apps.apple.com/us/app/allsports/id1555419187"
          >
            IOS Приложение
          </a>
        </div>
        <form
          method="get"
          action={
            "media/allsports-release.apk?" +
            "created=" +
            new Date().toDateString()
          }
        >
          <Button color="blue" type="submit">
            Android - скачать с сайта
          </Button>
        </form>
      </section>
      <ClientSticker language="by" />
      <GymSticker language="by" />
    </Layout>
  )
}

export default AppMain
